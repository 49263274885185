<template>
  <el-drawer
    title="第三方消費紀錄詳情"
    :visible="true"
    :append-to-body="true"
    destroy-on-close
    size="600px"
    class="reservation-order-detail"
    @close="$emit('close')"
  >
    <div class="px-[20px]">
      <FormInfo gap="10">
        <FormInfoItem
          v-for="info in baseInfoData"
          :key="info.label"
          width="132"
          :title="info.label"
          :content="info.value"
        >
          <Tag v-if="info.isTag" :type="info.tagType">{{ info.tagContent }}</Tag>
        </FormInfoItem>
      </FormInfo>
      <hr style="margin: 32px 0">
      <FormInfo gap="10">
        <p class="forminfo-title">消費品項</p>
        <div class="flex flex-col gap-[32px]">
          <FormInfo v-for="(info, idx) in salesData" :key="idx" gap="10">
            <FormInfoItem
              v-for="item in info"
              :key="item.value"
              width="132"
              :title="item.label"
              :content="item.value"
            />
          </FormInfo>
        </div>
      </FormInfo>
      <hr v-if="rewardData.length" style="margin: 32px 0">
      <FormInfo v-if="rewardData.length" gap="10">
        <p class="forminfo-title">本次可獲得</p>
        <FormInfoItem
          v-for="info in rewardData"
          :key="info.value"
          width="132"
          :title="info.label"
          :content="info.value"
        />
      </FormInfo>
      <hr style="margin: 32px 0">
      <FormInfo gap="10">
        <FormInfoItem
          v-for="info in footerData"
          :key="info.value"
          width="132"
          :title="info.label"
          :content="info.value"
        />
      </FormInfo>

      <div class="flex justify-end" style="margin-top: 70px;">
        <el-button plain style="width: 100px;" @click="$emit('close')">返回</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import FormInfo from '@/components/FormInfo/FormInfo.vue'
import FormInfoItem from '@/components/FormInfo/FormInfoItem.vue'
import Tag from '@/components/Tag/Tag.vue'
import { FindMemberExternalTransaction } from '@/api/memberExternalTransaction'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { get, map } from 'lodash'
import { formatDate, formatDateRange } from '@/utils/date'
import { rewardActivityTypeConfig } from '@/config/rewardActivity'
import { localeString } from '@/utils/helper'
import { salesItemFieldConfig, originsConfig, memberExternalTransactionStatusConfig } from '@/config/externalTransaction'

export default defineComponent({
  name: 'ExternalTransactionRecordDetail',
  components: { FormInfo, FormInfoItem, Tag },
  props: { record: { type: Object, default: () => ({}) } },
  setup (props, { emit }) {
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const recordData = ref({})
    const baseInfoData = computed(() => {
      const data = props.record
      const transactionTime = get(data, 'time')
      const isInvalid = get(data, 'isInvalid')
      const status = get(data, 'status')
      const origin = get(data, 'origin', '-')
      const fields = [
        { label: '消費紀錄編號', value: get(data, 'transactionId', '-') },
        { label: '來源', value: get(data, 'origin', '-') },
        { label: '會員手機號碼', value: get(data, 'phone', '-') },
        { label: '認列金額', value: localeString(get(data, 'amount', '-')) },
        { label: '實際消費金額', value: localeString(get(data, 'sellAmount', '-')) },
        { label: '交易時間', value: transactionTime ? formatDate(transactionTime) : '-' },
        { label: '是否作廢', tagContent: isInvalid ? '是' : '否', tagType: isInvalid ? 'error' : 'info', isTag: true },
        { label: '訂單狀態', tagContent: memberExternalTransactionStatus(status, 'label'), tagType: memberExternalTransactionStatus(status, 'type'), isTag: true },
      ]

      if (['dudoo', 'weiby'].includes(origin.toLowerCase())) {
        fields.splice(2, 0, { label: '交易門市', value: get(recordData.value, 'Branch.name', '-') })
      }
      return fields
    })
    const memberExternalTransactionStatus = (key, attr) => {
      return get(memberExternalTransactionStatusConfig[key], attr)
    }
    // 消費品項
    const salesData = computed(() => {
      const data = recordData.value
      return map(data.items, (item) => {
        const dataList = [
          { label: get(salesItemFieldConfig, `${data.source}.name.label`, '名稱'), value: get(item, 'name', '-') },
          { label: '金額', value: localeString(get(item, 'price', '-')) },
          { label: '數量', value: get(item, 'quantity', '-') },
        ]

        if (data.source === originsConfig.opentix.value) {
          dataList.push({
            label: get(salesItemFieldConfig, `${data.source}.eventTimeRange.label`, '起迄時間'),
            value: formatDateRange({
              dates: [
                get(item, 'context.opentixEventStartTime') * 1000,
                get(item, 'context.opentixEventEndTime') * 1000,
              ],
            }),
          })
        }

        // data.source 如果是 Pospal，則顯示 note 欄位
        if (data.source.toLowerCase() === originsConfig.pospal.value) {
          dataList.push({ label: '訂單備註', value: get(item, 'note', '-') })
        }

        if (data.source.toLowerCase() === originsConfig.drfoot.value) {
          // 將資料插入到陣列中第2個
          dataList.splice(1, 0, { label: '編號', value: get(item, 'itemNo', '-') })
        }

        return dataList
      })
    })

    const rewardData = computed(() => {
      const data = props.record
      const rewards = map(get(data, 'RewardActivityRecords'), reward => {
        let amount = get(reward, 'rewardAmount')
        if (!Number.isNaN(amount)) amount = amount.toLocaleString()
        if (reward.rewardType === 'CASHBACK') {
          return { label: rewardActivityTypeConfig.CASHBACK.label, value: `$ ${amount}` }
        } else if (reward.rewardType === 'POINT') {
          return { label: rewardActivityTypeConfig.POINT.label, value: `${amount} 點` }
        }
      })
      return rewards
    })

    const footerData = computed(() => {
      const data = recordData.value
      return [
        { label: '紀錄建立時間', value: data.createdAt ? formatDate(data.createdAt) : '-' },
      ]
    })

    onBeforeMount(async () => {
      if (!get(props.record, 'id')) return
      await simpleFetch(FindMemberExternalTransaction, {
        shopId: shopId.value,
        id: get(props.record, 'id'),
      }, (res) => {
        console.log('res', res)
        recordData.value = res
      })
    })

    return { baseInfoData, salesData, rewardData, footerData, recordData }
  },
})
</script>

<style lang="postcss" scoped>
.forminfo-title {
  @apply text-primary-100 font-medium text-normal;
}
</style>
