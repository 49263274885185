import { render, staticRenderFns } from "./ExternalTransactionRecordDetail.vue?vue&type=template&id=b34d1052&scoped=true"
import script from "./ExternalTransactionRecordDetail.vue?vue&type=script&lang=js"
export * from "./ExternalTransactionRecordDetail.vue?vue&type=script&lang=js"
import style0 from "./ExternalTransactionRecordDetail.vue?vue&type=style&index=0&id=b34d1052&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b34d1052",
  null
  
)

export default component.exports